import { handleErrorWithSentry } from "@sentry/sveltekit"
import * as Sentry from '@sentry/sveltekit'

Sentry.init({
  dsn: 'https://08549073ca80f451fc431978a8ce7066@o4508108592578560.ingest.de.sentry.io/4508108605227088',
  tracesSampleRate: 1,
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
