import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/admin": [3],
		"/filters": [~4],
		"/forms/details/page1": [~5],
		"/forms/details/page2": [~6],
		"/forms/details/page3": [~7],
		"/forms/details/page4": [~8],
		"/forms/gender": [~9],
		"/forms/onboarding": [10],
		"/forms/religion": [~11],
		"/home": [~12],
		"/inbox": [~13],
		"/inbox/conversations/[id]": [~14],
		"/login": [15],
		"/login/otp/[phoneNo]": [~16],
		"/payment/plans": [~17],
		"/payment/status/FAILED": [18],
		"/payment/status/PENDING": [19],
		"/payment/status/SUCCESS": [~20],
		"/payment/topup": [~21],
		"/privacy/phone": [~22],
		"/profiles/[id]": [~23],
		"/restore": [24],
		"/shortlists": [~25],
		"/support": [~26],
		"/views": [~27],
		"/vip": [~28]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';